<template>
	<div class="background">
		<div class="photo photo_water"></div>
		<div class="photo photo_energy"></div>
		<div class="photo photo_greenhouse_gases"></div>
		<div class="photo photo_waste"></div>
		<div class="photo photo_tvd"></div>
		<div class="photo photo_water_2"></div>
		<div class="photo photo_food"></div>
		<div class="photo photo_health"></div>
		<div class="photo photo_climate"></div>
	</div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
	data : function () {
		return {

		}
	},
	mounted(){

	},
	methods : {

	},
	computed : {
		
	}
}
</script>